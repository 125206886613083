import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { env } from './config'
import './index.sass'

Sentry.init({ dsn: 'https://e98ee0f201d4fa9fa1741a544f1dd646@o4509020979265536.ingest.us.sentry.io/4509024848445440', environment: env })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
