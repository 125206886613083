import { Cake, Coffee, EventAvailable, EventBusy, Schedule, WhatsApp } from '@mui/icons-material'
import { Avatar, CircularProgress, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Center } from '../../components/Center'
import { StatBox } from '../../components/StatBox'
import { sendWhatsApp } from '../../utils/contact'
import { formatDate, formatInteger, formatMoney, formatRelativeDate, formatTime } from '../../utils/format'
import { BoundModel, useBirthdaysQuery, useSessionsQuery, useSummary } from './api'

export function TherapistDashboard() {

  const summary = useSummary()

  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatInteger(summary.sessionCountLastMonth)} caption='Sessões no último mês' variant='info' />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatInteger(summary.sessionCountThisMonth)} caption='Sessões neste mês (até agora)' variant='info' />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatInteger(summary.sessionCountProjection)} caption='Projeção de sessões deste mês' variant='info' />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatMoney(summary.revenueLastMonth)} caption='Faturamento no último mês' variant='success' />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatMoney(summary.revenueThisMonth)} caption='Faturamento neste mês (até agora)' variant='success' />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatBox value={formatMoney(summary.revenueProjection)} caption='Projeção de faturamento deste mês' variant='success' />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TodaySessions />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Birthdays />
        </Grid>

      </Grid>

    </Stack>
  )
}

function TodaySessions() {
  const navigate = useNavigate()
  const { data, loading } = useSessionsQuery({
    startFrom: dayjs().startOf('day').toDate(),
    startTo: dayjs().endOf('day').toDate()
  })

  if (loading)
    return <Paper><Center minHeight={200}><CircularProgress /></Center></Paper>

  if (data?.sessionsToTherapist.length === 0)
    return (
      <Paper>
        <Center minHeight={200}>
          <Stack alignItems='center' gap={2}>
            <Coffee fontSize='large' color='disabled' />
            <Typography variant='subtitle1' color='text.secondary'>
              Não há sessões para hoje
            </Typography>
          </Stack>
        </Center>
      </Paper>
    )

  return (
    <Paper>
      <Typography variant='h6' padding={2}>
        Sessões de hoje
      </Typography>
      <List dense>
        {data?.sessionsToTherapist.map((session) => {
          let icon = <Avatar><Schedule /></Avatar>

          if (dayjs(session.start).isBefore(dayjs())) {
            if (session.present)
              icon = <Avatar sx={{ backgroundColor: 'success.light' }}><EventAvailable /></Avatar>
            else
              icon = <Avatar sx={{ backgroundColor: 'error.light' }}><EventBusy /></Avatar>
          }

          return (
            <ListItem key={session.id} secondaryAction={
              <IconButton edge="end" onClick={() => sendWhatsApp(session.bound.client.phoneNo)}>
                <WhatsApp />
              </IconButton>
            } disablePadding>
              <ListItemButton dense onClick={() => navigate(`/bounds/${session.bound.id}/session/${session.id}`)}>
                <ListItemAvatar>{icon}</ListItemAvatar>
                <ListItemText primary={session.bound.client.name} secondary={`${formatTime(session.start)} - ${formatRelativeDate(session.start)}`} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )
}

function Birthdays() {
  const navigate = useNavigate()
  const { data, loading } = useBirthdaysQuery()
  const [birthdays, setBirthdays] = useState<BoundModel[]>([])

  useEffect(() => {
    setBirthdays(
      data?.bounds?.sort((a, b) => dayjs(a.client.nextBirthday).diff(dayjs(b.client.nextBirthday)))?.slice(0, 5) ?? []
    )
  }, [data])

  if (loading)
    return <Paper><Center minHeight={200}><CircularProgress /></Center></Paper>

  if (birthdays.length === 0)
    return (
      <Paper>
        <Center minHeight={200}>
          <Stack alignItems='center' gap={2}>
            <Cake fontSize='large' color='disabled' />
            <Typography variant='subtitle1' color='text.secondary'>
              Não há aniversários para exibir
            </Typography>
          </Stack>
        </Center>
      </Paper>
    )

  return (
    <Paper>
      <Typography variant='h6' padding={2}>
        Próximos aniversários
      </Typography>
      <List dense>
        {birthdays.map((bound) => {
          return (
            <ListItem key={bound.id} secondaryAction={
              <IconButton edge="end" onClick={() => sendWhatsApp(bound.client.phoneNo)}>
                <WhatsApp />
              </IconButton>
            } disablePadding>
              <ListItemButton dense onClick={() => navigate(`/bounds/${bound.id}`)}>
                <ListItemAvatar><Avatar><Cake /></Avatar></ListItemAvatar>
                <ListItemText primary={bound.client.name} secondary={`${formatDate(bound.client.nextBirthday)} - ${formatRelativeDate(bound.client.nextBirthday)}`} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )
}
