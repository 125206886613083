import { jwtDecode } from 'jwt-decode'
import { ReactNode, createContext, useContext, useState } from 'react'

//reference: https://www.robinwieruch.de/react-router-authentication/

export interface AuthUser {
  name: string
  email: string
  isTherapist: boolean
  compliant: boolean
}

interface AuthContextProps {
  user: AuthUser | undefined
  updateUser: (accessToken: string, refreshToken: string) => void
  logout: () => void
}

export const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  updateUser: () => { },
  logout: () => { }
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {

  const [user, setUser] = useState<AuthUser | undefined>(loadUser)

  function loadUser() {
    const token = localStorage.getItem('access-token')
    if (!token) return undefined

    const payload = jwtDecode<AuthUser>(token)
    return {
      name: payload.name,
      email: payload.email,
      isTherapist: payload.isTherapist,
      compliant: payload.compliant,
    }
  }

  function updateUser(accessToken: string, refreshToken: string) {
    localStorage.setItem('access-token', accessToken)
    localStorage.setItem('refresh-token', refreshToken)

    setUser(loadUser())
  }

  function logout() {
    localStorage.removeItem('access-token')
    localStorage.removeItem('refresh-token')

    setUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ user, updateUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
