import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/react'

export class ExpectedError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ExpectedError'
  }
}

export function useErrorHandler() {
  const { enqueueSnackbar } = useSnackbar()

  function sendMessage(toUser: string | string[] | undefined, toConsole: any) {
    if (toConsole) {
      console.error(toConsole)
    }

    if (!toUser) {
      toUser = 'Ops, ocorrreu um erro inesperado :('
    }

    if (!Array.isArray(toUser)) {
      toUser = [toUser]
    }

    toUser.forEach((message) => {
      enqueueSnackbar(message, { variant: 'error' })
    })
  }

  return {
    handleError(e: any, friendlyMessage?: string) {
      if (e instanceof ExpectedError) {
        return sendMessage(e.message || friendlyMessage, e)
      }

      if (e.graphQLErrors) {
        return sendMessage(e.graphQLErrors.flatMap((a: any) => a.message), null)
      }

      captureException(e, { extra: { friendlyMessage } })
      return sendMessage(friendlyMessage, e)
    }
  }
}


