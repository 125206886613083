import { CalendarMonth, Dashboard, Payment, Person } from '@mui/icons-material'
import { JSX } from 'react'

export interface MainMenuItem {
  icon: JSX.Element
  text: string
  linkTo: string
  onlyTherapist: boolean
}

export const mainMenu: MainMenuItem[] = [
  { icon: <Dashboard />, text: 'Home', linkTo: '/', onlyTherapist: false },
  { icon: <CalendarMonth />, text: 'Calendário', linkTo: '/calendar', onlyTherapist: true },
  { icon: <Person />, text: 'Clientes', linkTo: '/bounds', onlyTherapist: true },
  { icon: <Payment />, text: 'Cobranças', linkTo: '/invoices', onlyTherapist: true },
]
