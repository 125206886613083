import { Button, Grid, Paper, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import { MuiTelInput } from 'mui-tel-input'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth/AuthProvider'
import { MoneyField } from '../../components/MoneyField'
import { PasswordField } from '../../components/PasswordField'
import { TextMaskField } from '../../components/TextMaskField'
import { useErrorHandler } from '../../hooks/error-handler'
import { useTherapistOnboardMutation } from './api'

interface OnboardData {
  name: string
  phoneNo: string
  email: string
  password: string
  birthDate: Dayjs | null,
  cpf: string
  addressZipCode: string
  addressStreet: string
  addressNumber: string
  addressComplement: string
  addressNeighborhood: string
  addressCity: string
  addressState: string
  license: string
  specializations: string
  biography: string
  site: string
  defaultSessionValue: number
}

export function Onboard() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [activeStep, setActiveStep] = useState(0)

  const [therapistOnboard] = useTherapistOnboardMutation()
  const navigate = useNavigate()
  const { handleError } = useErrorHandler()
  const { updateUser } = useAuth()

  const [onboardData, setOnboardData] = useState<OnboardData>({
    name: '',
    phoneNo: '',
    email: '',
    password: '',
    birthDate: null,
    cpf: '',
    addressZipCode: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    license: '',
    specializations: '',
    biography: '',
    site: '',
    defaultSessionValue: 0,
  })

  const handleSave = async () => {
    try {
      const { data } = await therapistOnboard({
        variables: {
          ...onboardData,
          birthDate: onboardData.birthDate!.toDate(),
          specializations: onboardData.specializations?.split(',')?.map(a => a.trim()),
        }
      })
      if (!data) throw new Error('Não foi possível criar o usuário')

      updateUser(
        data.therapistOnboard.tokens.access_token,
        data.therapistOnboard.tokens.refresh_token
      )

      navigate('/')
    } catch (e) {
      handleError(e, 'Não foi possível criar o usuário')
    }
  }

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)
  const onChangeField = (key: keyof OnboardData, value: any) => setOnboardData(old => ({ ...old, [key]: value }))

  return (
    <Stack p={4} gap={3}>
      <Stack>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>Onboarding</Typography>
        <Typography>Seja muito bem vindo(a) ao Psi do bem! Vamos preparar seu ambiente de trabalho.</Typography>
      </Stack>

      <Stack component={Paper} gap={3} padding={3}>
        {isSmallScreen ? (
          <>
            <Stepper activeStep={activeStep} orientation='vertical'>
              <Step>
                <StepLabel>Dados Pessoais</StepLabel>
                <StepContent><DadosPessoaisStep next={handleNext} data={onboardData} onChangeField={onChangeField} /></StepContent>
              </Step>
              <Step>
                <StepLabel>Endereço</StepLabel>
                <StepContent><EnderecoStep next={handleNext} back={handleBack} data={onboardData} onChangeField={onChangeField} /></StepContent>
              </Step>
              <Step>
                <StepLabel>Dados Profissionais</StepLabel>
                <StepContent><DadosProfissionaisStep save={handleSave} back={handleBack} data={onboardData} onChangeField={onChangeField} /></StepContent>
              </Step>
            </Stepper>
          </>
        ) : (
          <>
            <Stepper activeStep={activeStep}>
              <Step><StepLabel>Dados Pessoais</StepLabel></Step>
              <Step><StepLabel>Endereço</StepLabel></Step>
              <Step><StepLabel>Dados Profissionais</StepLabel></Step>
            </Stepper>

            {activeStep === 0 && <DadosPessoaisStep next={handleNext} data={onboardData} onChangeField={onChangeField} />}
            {activeStep === 1 && <EnderecoStep next={handleNext} back={handleBack} data={onboardData} onChangeField={onChangeField} />}
            {activeStep === 2 && <DadosProfissionaisStep save={handleSave} back={handleBack} data={onboardData} onChangeField={onChangeField} />}
          </>
        )}
      </Stack >
    </Stack>
  )
}

interface StepProps {
  next?: () => void
  back?: () => void
  data: OnboardData,
  onChangeField: (key: keyof OnboardData, value: any) => void
}

function DadosPessoaisStep(props: StepProps) {
  return (
    <Stack gap={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField label='Nome' variant='outlined' value={props.data.name} onChange={e => props.onChangeField('name', e.target.value)} required fullWidth helperText='Visível no seu perfil público' />
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiTelInput label='Celular/WhatsApp' variant='outlined' langOfCountryName="pt-BR" defaultCountry='BR' value={props.data.phoneNo} onChange={value => props.onChangeField('phoneNo', value)} required fullWidth helperText='Visível apenas para clientes' />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label='Email' variant='outlined' value={props.data.email} onChange={e => props.onChangeField('email', e.target.value)} fullWidth helperText='Visível no seu perfil público' />
        </Grid>
        <Grid item xs={12} md={6}>
          <PasswordField variant='outlined' value={props.data.password} onChange={e => props.onChangeField('password', e.target.value)} required fullWidth showRequirements />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker label="Nascimento" value={props.data.birthDate} onChange={date => props.onChangeField('birthDate', date)} slotProps={{ textField: { fullWidth: true, required: true } }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextMaskField label='CPF' variant='outlined' mask='000.000.000-00' value={props.data.cpf} onChange={e => props.onChangeField('cpf', e.target.value)} required fullWidth />
        </Grid>
      </Grid>

      <Stack direction='row' justifyContent='end'>
        <Button variant='contained' onClick={props.next}>Avançar</Button>
      </Stack>
    </Stack>
  )
}

function EnderecoStep(props: StepProps) {
  return (
    <Stack gap={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField label='CEP' variant='outlined' value={props.data.addressZipCode} onChange={e => props.onChangeField('addressZipCode', e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField label='Endereço' variant='outlined' value={props.data.addressStreet} onChange={e => props.onChangeField('addressStreet', e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label='Número' variant='outlined' value={props.data.addressNumber} onChange={e => props.onChangeField('addressNumber', e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label='Complemento' variant='outlined' value={props.data.addressComplement} onChange={e => props.onChangeField('addressComplement', e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label='Bairro' variant='outlined' value={props.data.addressNeighborhood} onChange={e => props.onChangeField('addressNeighborhood', e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField label='Cidade' variant='outlined' value={props.data.addressCity} onChange={e => props.onChangeField('addressCity', e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label='Estado' variant='outlined' value={props.data.addressState} onChange={e => props.onChangeField('addressState', e.target.value)} fullWidth />
        </Grid>
      </Grid>

      <Stack direction='row' justifyContent='end' gap={2}>
        <Button onClick={props.back}>Voltar</Button>
        <Button variant='contained' onClick={props.next}>Avançar</Button>
      </Stack>
    </Stack>
  )
}

function DadosProfissionaisStep(props: StepProps & { save: () => Promise<void> }) {
  return (
    <Stack gap={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField id='license' label='CRP' variant='outlined' value={props.data.license} onChange={e => props.onChangeField('license', e.target.value)} required fullWidth helperText='Visível apenas para clientes' />
        </Grid>
        <Grid item xs={12} md={6}>
          <MoneyField id='defaultSessionValue' label='Valor padrão da sessão' variant='outlined' value={props.data.defaultSessionValue} onChange={value => props.onChangeField('defaultSessionValue', value)} required fullWidth helperText='Informação privada' />
        </Grid>
        <Grid item xs={12}>
          <TextField id='specializations' label='Especializações' variant='outlined' value={props.data.specializations} onChange={e => props.onChangeField('specializations', e.target.value)} fullWidth helperText='Visível no seu perfil público' />
        </Grid>
        <Grid item xs={12}>
          <TextField id='site' label='Site' variant='outlined' value={props.data.site} onChange={e => props.onChangeField('site', e.target.value)} fullWidth helperText='Visível no seu perfil público' />
        </Grid>
        <Grid item xs={12}>
          <TextField multiline rows={10} id='biography' label='Bio' variant='outlined' value={props.data.biography} onChange={e => props.onChangeField('biography', e.target.value)} fullWidth helperText='Visível no seu perfil público' />
        </Grid>
      </Grid>

      <Stack direction='row' justifyContent='end' gap={2}>
        <Button onClick={props.back}>Voltar</Button>
        <Button variant='contained' onClick={props.save}>Salvar</Button>
      </Stack>
    </Stack>
  )
}
