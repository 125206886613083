import { gql, useMutation } from '@apollo/client'

const therapistOnboardMutation = gql`
  mutation TherapistOnboard(
      $name: String!,
      $email: String!,
      $phoneNo: String!,
      $birthDate: DateTime!,
      $cpf: String!,
      $password: String!,
      $addressZipCode: String,
      $addressStreet: String,
      $addressNumber: String,
      $addressComplement: String,
      $addressNeighborhood: String,
      $addressCity: String,
      $addressState: String,
      $license: String!,
      $specializations: [String!],
      $biography: String,
      $site: String,
      $defaultSessionValue: Int
      ) {
    therapistOnboard(
        data: {
            name: $name
            email: $email
            phoneNo: $phoneNo
            birthDate: $birthDate
            cpf: $cpf
            password: $password
            addressZipCode: $addressZipCode
            addressStreet: $addressStreet
            addressNumber: $addressNumber
            addressComplement: $addressComplement
            addressNeighborhood: $addressNeighborhood
            addressCity: $addressCity
            addressState: $addressState
            license: $license
            specializations: $specializations
            biography: $biography
            site: $site
            defaultSessionValue: $defaultSessionValue
        }
    ) {
        tokens {
            access_token
            refresh_token
        }
    }
  }
`

type TherapistOnboardInput = {
  name: string
  email: string
  phoneNo: string
  birthDate: Date
  cpf: string
  password: string
  addressZipCode?: string
  addressStreet?: string
  addressNumber?: string
  addressComplement?: string
  addressNeighborhood?: string
  addressCity?: string
  addressState?: string
  license: string
  specializations: string[]
  biography: string
  site: string
  defaultSessionValue: number
}

interface TherapistOnboardOutput {
  therapistOnboard: {
    tokens: {
      access_token: string
      refresh_token: string
    }
  }
}

export const useTherapistOnboardMutation = (variables?: TherapistOnboardInput) => useMutation<TherapistOnboardOutput, TherapistOnboardInput>(therapistOnboardMutation, { variables })
