import { ApolloClient, InMemoryCache, createHttpLink, from, fromPromise, gql } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { graphqlUrl } from '../config'

const httpLink = createHttpLink({
  uri: graphqlUrl,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('access-token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
})

const GET_TOKEN_QUERY = gql`
  mutation RefreshToken($refresh_token: String!) {
    refreshToken(refresh_token: $refresh_token) {
        tokens {
            access_token
            refresh_token
        }
    }
  }
`

const getNewToken = async () => {
  const refreshToken = localStorage.getItem('refresh-token')
  const { data } = await apolloClient.mutate({ mutation: GET_TOKEN_QUERY, variables: { refresh_token: refreshToken } })
  return data.refreshToken.tokens
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (!graphQLErrors) return
  if (!graphQLErrors.some((err) => err.extensions?.code === 401)) return

  const operationsWithoutAuth = ['Login', 'RefreshToken']
  if (operationsWithoutAuth.includes(operation.operationName)) return

  const newTokenPromise = getNewToken()
    .then((tokens) => {
      localStorage.setItem('access-token', tokens.access_token)
      localStorage.setItem('refresh-token', tokens.refresh_token)
      return tokens
    })
    .catch(() => {
      localStorage.removeItem('access-token')
      localStorage.removeItem('refresh-token')
      window.location.href = '/login'
    })

  return fromPromise(newTokenPromise)
    .filter(tokens => tokens)
    .flatMap(() => forward(operation))
})


export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'no-cache' },
    query: { fetchPolicy: 'no-cache' },
  },
})
